@import 'globals';


html {

}

html, body {
        margin:0;
        padding:0;
        height:100%;
}

body {
        background-color:$bg_color;
        font-family: 'Abel', sans-serif;

        margin:0;
        padding:0;
        position:relative;

}
ul {
        list-style:none;
        margin:0;
        padding:0;
}
ul li {
        padding:0;
        margin:0;
}
ul.normal {

        &.big {
                font-size:1.2em;
                li {
                        padding-top:.4em;
                        padding-bottom:.4em;
                }
        }

        li {

                padding-left: 2em;
                text-indent:-2em;
        }
        li:before {
                content: "• ";
                display:inline-block;
                width:2em;
                text-indent:0;
                text-align:center;
                color: $valota_orange;
        }
}

.input_holder {
        margin:1em;

}

table.input_holder {
        margin:1em auto;
        border:0;
}
table.data_holder {
        margin:1em auto;
        border:0;
        font-size:1.3em;
}
table.data_holder td {
        padding:.6em 0;

        .info {
                font-size:.8em;
                white-space:normal;
                text-transform:none;
                max-width:300px;

        }
}
table.data_holder td:first-child {
        color:$lighttext;
        text-transform:uppercase;
        padding-right:1em;
        text-align:right;
        font-size:.7em;

}
table.data_holder tr:nth-child(even) td {
        background-color:rgba(0,0,0,.02);
}


.input_holder td {
        vertical-align:middle;
        padding:.5em 0;
}
.input_holder td:first-child {
        text-align:right;
        width:40%;
}
.input_holder td:last-child {
        text-align:left;
}

h3,h4 {
        padding:.6em 0;
        font-family: 'Abel', sans-serif;

        color:$lighttext;
        margin:0;
        font-weight:normal;
        text-transform:uppercase;
}
h3 {
        font-size:1.6em;

}
h4 {
        font-size:1.2em;

}

a:link, a:active, a:visited {
        font-family:'Droid Serif', serif;
        color:$lighttext;
        font-style:italic;
        text-decoration:underline;
        position:relative;
}
a:hover {
        color:$valota_orange;
}
.link {
        font-family:'Droid Serif', serif;
        color:$lighttext;
        font-style:italic;
        text-decoration:underline;
        position:relative;
        display:inline-block;
        cursor:pointer;
}
.link:hover {
        color:$valota_orange;
}
.heading_div {
        position:relative;
        text-align:center;
}
.heading_div h3 {
        padding:.6em 3.4em;
        z-index:10;
        bottom:.5em;
        position:relative;
}
.subtle_link {

        border-bottom:1px dotted $valota_orange_light;
        cursor:pointer;
        &:hover {
                border-bottom:1px dotted $valota_orange;
                color:black;
        }

}

.input_error {
        border-bottom-color:$valota_orange_light;
}

.input_error:focus {
        border-bottom-color:$valota_orange;
}
textarea.input_error {
        border-color:$valota_orange_light;
}
textarea.input_error:focus {
        border-color:$valota_orange;
}
.error_mes {
        left:-20px;
        position:relative;
        opacity:0;
        width:0px;
        overflow:visible;
        white-space:nowrap;
        transition:all .6s;
        color:$valota_red;
        font-size:.8em;
        display:inline-block;
}

.error_mes.error_ok {
        color:$valota_green_2;
}
.error_mes.under {
        display:block;
        width:auto;
        white-space:normal;
}


input.tepid,
select.tepid,
textarea.tepid {

        border:0;
        border-bottom:1px solid rgba(0,0,0,.1);
        font-family: 'Abel', sans-serif;
        font-size:1em;
        padding:.7em .9em;
        vertical-align:middle;
        height:3em;
        box-sizing:border-box;
        width:400px;
        transition:padding .4s, top .4s, border-bottom 1s;
        background-color:transparent;
        position:relative;
        top:0;


}
.tepid.no_border {
        border-bottom:0;
}


textarea {
        border:1px solid #ccc;
}
input:not(.color):focus,select:focus {
        &.tepid {
                padding-bottom:0!important;
                border-bottom-color: black;
                top:-.35em;
                outline: 0;
        }
}
textarea {
        height:6em;
}


button.toggle {
        background-color:transparent;
        padding:.3em .6em;
        color:$lighttext;
        border:0;

        &:focus {
                outline:0;
        }

        &:before {
                font-family:"FontAwesome";
                width:1em;
                display:inline-block;

                text-align:center;
                color:$valota_green;
        }

        &[data-toggle="off"]:before {
                content:'\f096';
                opacity:.3;


        }
        &[data-toggle="on"]:before {
                content:'\f046';
                opacity:1;
        }
        &.dark {
                color:$darktext;
                font-size:1.2em;
        }
        &:hover {

                box-shadow:0 0 2px #bbb;
        }
}

button.big_green {
        width:220px;
        padding:0 3.5em;
        height:3em;
        box-sizing:border-box;
        background-color:$valota_green;
        color:white;
        box-sizing:border-box;
        text-transform:uppercase;
        border:0;
        position:relative;

        &:disabled {
                background-color:$buttonbg;
        }
        &.auto_width {
                width:auto;
                display:inline-block;
        }
        &.cancel {

        }
}

button.btn {
        background-color:transparent;
        border:0;
        color:rgba(0,0,0,.7);
        border-radius:3px;
        padding:.3em .5em;
        margin:2px;
        font-size:1em;
        text-transform:uppercase;
        font-family:'Abel', sans-serif;
        white-space:nowrap;

        &.right-top {
                top:0;
                right:0;
                position:absolute;

        }

        &:disabled {
                opacity:.2;
        }
        &.small {
                color:rgba(0,0,0,.5);
                font-size:.8em;
        }
        &.medium {
                font-size:1.2em;
        }
        &.large {
                color:rgba(0,0,0,.6);
                font-size:1.4em;
                min-width:6em;
                &:enabled:hover {
                        color:rgba(0,0,0,1);
                        box-shadow:0 0 4px rgba(0,0,0,.6);
                }

                &:before {
                        font-family:"FontAwesome";
                        display:block;
                        font-size:2em;
                        text-align:center;
                        padding-bottom:.5em;
                        padding-right:0;
                        opacity:.3;
                        color:$valota_green;
                }
        }
        &:before {
                font-family:"FontAwesome";
                display:inline-block;
                padding-right:.5em;
                opacity:.3;
        }
        &:after {
                font-family:"FontAwesome";
                display:inline-block;
                padding-left:.5em;
                opacity:.3;
        }

        &.after-trash:after {
                content:'\f014';
        }
        &:enabled {

                &:active {
                        box-shadow:0 0 3px rgba(0,0,0,.8);
                        border:0;
                        outline:0;
                }

                &:hover {
                        color:rgba(0,0,0,1);
                        box-shadow:0 0 2px rgba(0,0,0,.6);
                }



                &:before, &:after {
                        opacity:1;
                        color:$valota_green;
                }


        }
        &.red:enabled:hover:before, &.red:enabled:hover:after {
                color:$valota_red;
        }
        &.red:enabled:before, &.red:enabled:after {
                color:$valota_red;
        }

        &.orange:enabled:hover:before, &.orange:enabled:hover:after {
                color:$valota_orange;
        }
        &.orange:enabled:before, &.orange:enabled:after {
                color:$valota_orange;
        }

        &.gray:enabled:hover:before, &.gray:enabled:hover:after {
                color:$lightgray;
        }
        &.gray:enabled:before, &.gray:enabled:after {
                color:$lightgray;
        }

        &.subtle:enabled:before, &.subtle:enabled:after {
                color:$lightgray;
        }



        &:focus {
                border:0;
                outline:0;
        }
}

span.icon {
        display: inline-block;
        padding:.3em .5em;
        color:rgba(0,0,0,.7);
        margin:2px;
        font-size:1em;
        text-transform:uppercase;
        font-family:'Abel', sans-serif;
        white-space:nowrap;

        &:before {
                font-family:"FontAwesome";
                display:inline-block;
                padding-right:.5em;
                color:$lightgray;
        }
}

div.infobox {
        background-color:white;
        box-shadow:0 1px 2px rgba(0,0,0,.2);
        max-width:80%;
        text-align:justify;
        white-space:normal;
        padding:1em;
        margin:1em auto;
}

#login_menu {

        position:relative;
        z-index:15;
        &.hidden {
                display:none;
        }
}

#account_div {


        opacity:1;

        position:relative;
        z-index:14;
        text-align:right;


        transition:opacity 1s;

        &.hidden {
                opacity:0;
        }
        .account_text {
                display:inline-block;

                vertical-align:middle;

        }
        .account_icon {

                &:hover {
                        color:$valota_orange;
                }
        }
        #logout_name {
                cursor:pointer;
                &:after {
                        display:inline-block;
                        content:'\f107';
                        vertical-align:middle;


                        padding:.5em;
                        margin-left:.5em;
                        color:$lighttext;
                        cursor:pointer;
                        font-family:'FontAwesome';
                }
        }
        #logout_name:hover:after {
                color:$valota_orange;
                background-color:white;
                box-shadow:0 1px 2px #ccc;
        }

        span {
                cursor:pointer;
                color:$lighttext;
        }
        span:hover {
                color:$valota_orange;
        }
}

#mobile_menu {
        font-family: FontAwesome;
        font-size: 1em;
        height: 2.6em;
        width: 2.6em;
        display: none;
        text-align: center;
        background-color: #555;
        color: white;
        border-radius: 1.3em;
        line-height: 2.6;
        float: right;
        margin-left: 1em;
        vertical-align: middle;
        opacity: .4;
        cursor: pointer;
        &:hover {
                opacity:1;
        }
}

#big_loading_all {

        z-index:99;
        width:100%;

        position:absolute;
        left:0;
        right:0;
        bottom:0;
        height:2px;

        overflow:hidden;

        .load_div_box {
                width:2%;
                left:-2%;
                position:absolute;
                bottom:0;
                height:100%;
                background-color:$valota_orange;

                &.first {

                        animation-delay:0s;
                }

                &.second {
                        animation-delay:1s;
                }

                &.third {
                        animation-delay:2s;
                }

        }
}

.form_reply_messages {
        padding:.6em .3em;
}


@keyframes loading_animation {
        0% {
                transform:rotate(-15deg);

        }

        100% {
                transform:rotate(345deg);

        }
}

.form_reply_messages {
        opacity:0;
        transition:opacity .3s;
        color:$valota_orange;

}



.box {
        position:relative;

}

.box .flex_container {

}
.box.white, .box .padded, .padd_cont {
        padding:15px;
        margin-bottom:7px;
        box-sizing:border-box;
        background-color:$bg_color_2;
        box-shadow:0 1px 2px rgba(0,0,0,.15);
        width:100%;
        position:relative;

}

.padd_cont.nopad {
        padding:0;
}
.padd_cont.tp {
        box-shadow:none;
        background-color:transparent;
}
.padd_cont.flex {
        display:flex;
        flex-wrap:wrap;
        justify-content:space-between;

}



.padd_cont.nopad.transparent {
        background-color:transparent;
        box-shadow:none;
}

.padd_cont {
        width:100%;
}



.box.page {

        flex-wrap:wrap;
        visibility:hidden;

}
.box.display_page {
        visibility:visible;
        display:block;

}


#cg_header {
        padding:2em;
        margin-bottom:0;
        white-space:nowrap;
}

#upload_header {
        padding:2em;
        white-space:nowrap;
}

#main_logo {
        height:40px;
        width:130px;
        display:inline-block;
        background:url('#{$img_base_folder}valotalive_logo.png') center center no-repeat;
        background-size:contain;
        vertical-align:middle;
        .with {
                display:none;
        }
        &.reseller {
                width:70px;
                height:25px;
                position:relative;
                opacity:.8;
        }
        &.reseller .with {
                display:block;
                color:$lighttext;
                font-size:.8em;
                position:absolute;
                top:-1.1em;
                left:.1em;
        }
}


.main_header {
        font-size:1.4em;
        text-transform:uppercase;
        vertical-align:middle;
        color:$lightgray;
        text-align:left;
        display:inline-block;


}
#container {
        position:relative;
        margin:auto;
        transition:.3s ease-in width;
        /*min-height:100%; /* old value */
        background-color:$bg_color;

}


#main_menu {
        position:relative;
        text-align:center;
        white-space:nowrap;
        font-size:1.1em;
        width:100%;
        overflow:hidden;
        z-index:13;
        background-color:#fefefe;
        margin-bottom:7px;
        box-shadow: 0 1px 2px rgba(0,0,0,.15), 0 1px 1px rgba(0,0,0,.15) inset;

        li {
                padding:.8em 1em .4em;
                text-align:left;
                display:inline-block;
                transition:.5s color;
                cursor:pointer;
                color:#606060;
                text-transform:uppercase;
                white-space:nowrap;
                border-bottom:2px solid transparent;
        }
        li:before {
                display:inline-block;
                padding-right:.6em;

                font-family:"FontAwesome";
                width: 1.6em;
                text-align: left;

        }



        .chosen, li:hover {
                color:$valota_orange;
                border-bottom:2px solid $valota_orange;
        }
        .chosen:before, li:hover:before {
                color:$valota_orange;
        }
}

.fast_message_saved {
        display:inline-block;
        vertical-align:middle;
        color:$valota_orange;
        animation-name: fast_message_right;
        animation-duration:4s;
}

@keyframes fast_message_right {
        0%   { opacity:1; transform:translateX(0px); }
        40% {opacity:0; transform:translateX(100px);}
        100% {opacity:0; transform:translateX(100px);}
}


.fast_message {
        position:absolute;

        color:$valota_orange;
        display:block;
        width:200px;
        left:-210px;
        top:0;
        font-weight:normal;
        white-space:nowrap;
        height:20px;
        text-align:right;
        z-index:100;


        animation-name: fast_message;
        animation-duration: 5s;

}



@keyframes fast_message {
        0%   { opacity:1; transform:translateX(0px); }
        40% {opacity:0; transform:translateX(-100px);}
        100% {opacity:0; transform:translateX(-100px);}
}
.mes_holder {
        position:relative;
}

.cover_all {
        background-color: rgba(255,255,255,0);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        transition:.5s background-color;
        z-index: 999;
        display:none;
        overflow:hidden;


        .close {
                position:absolute;

                line-height:2vh;
                height:4vh;

                box-sizing:border-box;
                padding:1vh;
                color:white;
                background-color:$darktext;
                cursor:pointer;
                top:0;
                border-bottom-left-radius:3px;
                border-bottom-right-radius:3px;

                text-align:center;
                width:10vh;
                left:47%;
                left:calc(50% - 5vh);
                &:hover {
                        background-color:black;

                }

                &:before {
                        padding-right:.3em;
                }
                &.hidden {
                        display:none;
                }
        }
}
#cover_all {
        z-index:1000;
}
#prompt {
        left:30%;
        left: calc(50% - 350px);
        top:10%;
        width:700px;
        max-height:80%;
        overflow:auto;

        text-align:center;
        box-sizing:border-box;
        position:absolute;
        padding:1em;
        background-color:white;
        box-shadow:0 1px 3px #bbb;
        transform:translateY(-20px);
        opacity:0;
        transition:.5s all;
}
#prompt_head {
        font-size: 2em;
        color: #aaa;
}
#prompt_info {
        padding: 1em 1em 0;
        opacity:.7;
        display:inline-block;
        font-style:italic;
        text-align: justify;
}
.prompt_messages {
        padding:.6em 0;
        border-bottom:1px solid #ccc;
        text-align:center;
}
.prompt_messages:last-child {
        border-bottom:0;
}
.edit_icon {
        color:rgba(0,0,0,.1);
        cursor:pointer;
        padding:0 .5em;
        vertical-align:middle;
}
.edit_icon:hover {
        color:rgba(0,0,0,1);
}

table caption {
        text-transform:uppercase;

        color:rgba(0,0,0,.3);

}
table.data {
        border-collapse: collapse;
        width:100%;
        th {
                padding:1em .4em;
                font-weight:normal;
                text-align:center;
                color:$lighttext;
                border-bottom:1px solid $lightgray;
        }
        td {
                padding:1em .4em;
                text-align:center;
                border-bottom:1px solid $lightgray;
        }
        td:first-child, th:first-child {
                text-align:right;
        }
        td:last-child, th:last-child {
                text-align:left;
        }

        tbody tr:hover {
                background-color:$valota_yellow;
        }

}

.smiley_face:before, .normal_face:before {
        font-family:'FontAwesome';
        display:inline-block;
        margin-right:.3em;
        font-size:1.2em;

}
.panic_face:before {
        content: "\f071";
        color:red;
        text-shadow:0px 0px 10px yellow;
}
.sad_face:before {
        content: "\f119";
        color:red;
}
.normal_face:before {
        content: "\f11a";
        color:darkgoldenrod;
}
.smiley_face:before {
        content: "\f118";
        color:green;

}

.big_text {
        font-family: 'Droid Serif', serif;
        color:$valota_green;
        font-size:2em;
        font-style:italic;
        text-align:center;
}

.big_ad_container {
        width:100%;
        background-color:white;
        height:550px;
        overflow:hidden;
        position:relative;
        .big_ad_list {
                white-space:nowrap;
                width:100%;
                height:100%;

                position:relative;
                left:0;


                .big_ad{
                        width:100%;
                        height:100%;
                        vertical-align:middle;
                        display:inline-block;

                }
                .example_spot {
                        padding:5%;
                        width:570px;
                        margin:auto;
                        white-space:normal;
                        position:relative;

                }
                .preview_image {
                        width:100%;
                        height:100%;
                        background-position:center bottom;
                        background-repeat:no-repeat;
                        background-image:url('#{$img_base_folder}serving_content.jpg');
                        background-size:contain;
                }

        }
}
.tabbed_menu {
        list-style-type:none;
        white-space:nowrap;
        overflow:auto;
        text-align:center;
        border-bottom:1px solid #e7e7e7;
}
.tabbed_menu li {
        display:inline-block;
        margin:0 .8em;
        padding-bottom:.3em;
        color:$adeledark;
        font-size:1.3em;
        text-transform:uppercase;
        cursor:pointer;
        border-bottom:2px solid transparent;

}
.tabbed_menu li.chosen, .tabbed_menu li:hover {
        color:$valota_orange;
        border-bottom:2px solid $valota_orange;

}

.tabbed_menu li.chosen:before,.tabbed_menu li:hover:before {
        color:$valota_orange;
}
.tabbed_menu li:before {
        font-family:'FontAwesome';
        font-size:1.2em;
        padding-bottom:.4em;
        display:block;

        text-align:center;
}

.tabbed_container {

        overflow:hidden;

        .tabbed_container_list {
                position:relative;
                white-space:nowrap;
                transition:left 1s;
                left:0;

                .tabbed_page {
                        width:100%;
                        vertical-align:top;
                        display:inline-block;
                        overflow:hidden;




                }
        }

}


.bold {
        font-weight:bold;
}

.notice_div {
        padding:1em;





        text-align:left;
        background-color:white;
        box-shadow:0 1px 2px #ccc;

        font-family:"Droid Serif", serif;


        &:before {
                font-family:'FontAwesome';
                font-size:2em;
                margin-right:.5em;
                display:inline-block;
                vertical-align:middle;


        }

        &.error {

                color:$valota_red;

                &:before {
                        content:'\f057';
                }

        }
        &.success{

                color:rgb(55,181,117);

                &:before {
                        content:'\f00c';
                }

        }

        &.warning {

                color:rgb(150,150,50);

                &:before {
                        content:'\f071';
                }

        }
        &>div {
                display:inline-block;
                vertical-align:middle;
        }

}


.toggle_container {
        display:inline-block;
        cursor:pointer;
}

.toggle_switch {
        width:3em;
        height:1.5em;
        border-radius:1em;
        vertical-align:middle;
        display:inline-block;
        background-color:$lightgray;
        position:relative;
        cursor:pointer;
        .toggle_ball {
                width:1.5em;
                height:1.5em;
                border-radius:1em;
                background-color:$valota_orange;
                position:absolute;
                left:0;
                top:0;
                transition:left .5s;
        }
}
.toggle_text {


        opacity:.3;
        transition:opacity .5s;
        cursor:pointer;

        &.chosen,&:hover {
                opacity:1;


        }
}

.background_animation {
        position:absolute;
        left:0;
        top:0;
        right:0;
        bottom:0;
        z-index:0;
        overflow:hidden;
        pointer-events: none;

        .ball {


                position:absolute;


                transform-origin:-10% -20%;

                animation-name:background_ball_animation;
                animation-fill-mode:both;
                animation-play-state:play;
                animation-duration:50s;
                animation-iteration-count:infinite;
                animation-timing-function:ease;
                &.one {
                        animation-duration:50s;
                        width:400px;
                        height:400px;
                        border-radius:200px;
                        left:50%;
                        background-color:$valota_orange;
                }
                &.two {
                        animation-duration:40s;
                        width:200px;
                        height:200px;
                        border-radius:100px;
                        left:15%;
                        background-color:$valota_green;
                        animation-direction:reverse;
                }
        }
}
.context_menu_container {
        position:relative;
        display:inline-block;

        text-align:right;
        &:hover .context_icon {
                background-color:white;
                box-shadow:0 1px 2px #ccc;

        }
}
.context_icon {

        font-size:.7em;
        line-height:1em;
        padding:1em;
        display:inline-block;
        cursor:default;
        font-family:'FontAwesome';

}

.context_menu {
        height:0;
        overflow:hidden;
        position:absolute;
        padding:0;
        box-sizing:border-box;

        z-index:100;
        opacity:0;
        transition:opacity .5s, height .5s;

        min-width:100%;

        &.right {
                left:0;
                text-align:left;


                li:before {
                        display:inline-block;
                        font-family:'FontAwesome';
                        padding-right:.8em;
                }
        }
        &:not(.right) {
                text-align:right;
                right:0;

                li:after {
                        display:inline-block;
                        font-family:'FontAwesome';
                        padding-left:.8em;
                }
        }

        &:not(.right) li.logout:after, &.right li.logout:before {
                content:'\f08b';
        }
        &:not(.right) li.user:after, &.right li.user:before {
                content:'\f007';
        }
        &:not(.right) li.play:after, &.right li.play:before {
                content:'\f04b';
        }
        &:not(.right) li.edit:after, &.right li.edit:before {
                content:'\f044';
        }
        &:not(.right) li.copy:after, &.right li.copy:before {
                content:'\f08e';
        }
        &:not(.right) li.delete:after, &.right li.delete:before {
                content:'\f014';
        }
        &:not(.right) li.verify:after, &.right li.verify:before {
                content:'\f046';
        }
        &:not(.right) li.user_toggle.off:after, &:not(.right) li.client_toggle.off:after,
                &.right li.user_toggle.off:before, &.right li.client_toggle.off:before {
                content:'\f00d';
        }

        &:not(.right) li.settings:after {
                content:'\f013';
        }
        &:not(.right) li.admin:after {
                content:'\f013';
        }
        &:not(.right) li.user_toggle.on:after, &:not(.right) li.client_toggle.on:after,
                &.right li.user_toggle.on:before, &.right li.client_toggle.on:before {
                content:'\f00c';
                color:$valota_green!important;
                opacity:1!important;
        }


        ul {

                position:relative;


                background-color:white;
                box-shadow:0 1px 2px #ccc;
                white-space:nowrap;
                padding:.4em 0;
                li {
                        padding:.4em .8em;
                        cursor:pointer;
                        transition:color .4s;
                        color:#606060;
                        text-transform:uppercase;
                }


                li[data-enabled='0'] {
                        opacity:.2;
                        cursor:initial;
                }

                li:not([data-enabled='0']):hover, li:not([data-enabled='0']):hover:after {
                        color:$valota_orange;
                }
        }
}

.switch {
        border-radius:12px;
        height:24px;
        font-size:12px;
        line-height:24px;
        background-color:#ddd;


        box-shadow:0 1px 4px rgba(0,0,0,.4) inset;
        width:60px;
        position:relative;
        display:inline-block;
        text-align:center;
        cursor:pointer;

        &:before {
                height:20px;
                width:20px;
                border-radius:10px;
                top:2px;
                box-sizing:border-box;
                border:1px solid rgba(0,0,0,.2);
                transition:left .4s;



                content:'';
                display:block;
                position:absolute;
        }

        &[data-active='0'] {
                color:$lighttext;

                &:before {
                        left:2px;
                        background-color:$lighttext;
                }

                &:after {
                        padding-left:22px;
                        content:'OFF';
                }

        }

        &[data-active='1'] {

                &:before {
                        left:38px;
                        background-color:$valota_green;
                }

                &:after {
                        padding-right:22px;
                        content:'ON';
                }
        }

}
.left_top_corner {
        position:absolute;
        left:1em;
        top:1em;
}
.right_top_corner {
        position:absolute;
        right:1em;
        top:1em;
}

.action {
        padding:.3em .6em;
        cursor:pointer;
        white-space:nowrap;



        &.warning {
                box-shadow:0 1px 1px rgba(255,0,0,.2);
                color:$valota_red;
                background-color:rgba(255,0,0,.02);
                border-radius:4px;

                &:hover {
                        box-shadow:0 1px 1px rgba(255,0,0,.4);
                        background-color:rgba(255,0,0,.04);

                }
        }
}

#vl_pop_up_window {

        .content {
                width:100%;
                height:100%;
                top:0;
                left:0;
                position:absolute;


                .popup {
                        position:absolute;
                        width:100%;
                        height:100%;
                        overflow:auto;
                        text-align:center;
                        display:flex;
                        flex-direction:column;
                        overflow:auto;
                        &>div {
                                margin:auto;
                                padding:1em;
                        }
                }

        }
}
.terms {
        text-align:justify;
        max-width:800px;
        padding:0 1em;
        font-family:"Droid Serif", serif;
}

.country_select {
        padding-left:35px;
        background-repeat:no-repeat;
        background-position:left center;
        background-size:20px auto;

}
#payment_method {
        min-width:18em;

}

.subtle_text {
        font-size:.8em;
        color:$lighttext;
}
p.normal {
        color:$darktext;
        max-width:700px;
        margin:1em auto;
        padding:1em;
        text-align:justify;
        white-space:normal;
}
.edit_box {
        position:relative;
        margin:.5em;
        padding:1em;
        background-color:white;
        color:$darktext;
        box-shadow:0 1px 2px rgba(0,0,0,.2);
}

span.error{
        color:$valota_red;
        background-color:$valota_red_bg;
        font-weight:bold;
        line-height:1.6em;
        padding-left:0.3em;
        padding-right:0.3em;
}
.green {
        color:$valota_green;
}

.note {

        border:1px solid rgba(0,0,0,.1);
        border-bottom:0px;
        padding:.8em;
        padding-left:2.8em;
        color:$darktext;
        font-size:.9em;
        text-align:left;
        position:relative;


        &:last-child {
                border-bottom:1px solid rgba(0,0,0,.1);
        }

        &:before {
                display:inline-block;
                top:1em;
                left:.4em;
                position:absolute;
                width:2em;

                text-align:center;
                font-family:'FontAwesome';

        }
        &.warning:before {
                color:$valota_orange;
                content:'\f071';
        }
        &.error:before {
                color:$valota_red;
                content:'\f056';
        }

        &.error {
                background-color:$valota_red_bg;
        }
        &.warning {
                background-color:$valota_orange_bg;
        }
        &.notice {
                background-color:$valota_green_bg;
        }

        &.notice:before {
                color:$valota_green;
                content:'\f111';
        }
        .edit {
                border-bottom:1px dotted $valota_orange;
                cursor:pointer;
                &:hover {
                        border-bottom:1px solid $valota_orange;
                }
        }

}



#reseller_div {
        display:none;
        margin-left:1em;
        width:80px;
        height:40px;
        position:relative;
        vertical-align:middle;

        &.active {
                display:inline-block;
        }
}
#reseller_logo {
        width:80px;
        height:70px;
        position:absolute;
        top:-15px;
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
}
#reseller_name {
        display:none;
        text-transform:none;
        white-space:nowrap;
        color:$adeledark;
        &.active {
                display:block;
        }

}

ol.legal { counter-reset: item }
ol.legal>li { display:block;position:relative; }
ol.legal>li:before { content: counters(item, ".") " "; counter-increment: item; position:absolute; left:-30px;top:-2px; }
