$valota_orange_dark: rgb(220,85,35);
$valota_orange: rgb(240,115,55);
$valota_orange_light: rgba(240,115,55,.5);
$valota_orange_bg: rgb(250,240,225);
$valota_green: rgb(55,181,117);
$valota_green_bg: rgba(55,181,117,.12);
$valota_green_2: rgb(0,195,5);
$valota_yellow: #f5da73;
$valota_red: rgb(229,0,25);
$valota_red_bg: rgba(229,0,25,.15);

$valota_blue: rgb(55,115,255);


$bg_color: #eee;
$bg_color_2: #fafafa;
$shadow: rgba(0,0,0,.15);
$def-margin: 1em;
$def-padding: 2em;
$white:white;
$darktext:rgb(50,50,50);
$adeledark:#606060;
$lighttext:rgba(0,0,0,.5);
$buttonbg:rgb(170,170,170);
$lightgray:rgba(0,0,0,.2);

$img_base_folder:'/img/'; /* FILTERVARDEFINE '/img/'; */
$fonts_base_folder:'/fonts/'; /* FILTERVARDEFINE '/fonts/'; */

