@import 'globals';

@keyframes from_right {
  from { left:100px;opacity:0; }
  to { left:0px;opacity:1; }
}

.va_from_right {
  animation-name:from_right;
  animation-fill-mode:both;
  animation-iteration-count:1;
  position:relative;
  
  
}

@keyframes to_right {
  from { left:0px;opacity:1; }
  to { left:100px;opacity:0; }
}

.va_to_right {
  animation-name:to_right;
  animation-fill-mode:both;
  animation-iteration-count:1;
  position:relative;
 }
 
 @keyframes to_down {
  0% { transform:translateY(0px);opacity:1; }
  100% { transform:translateY(100px);opacity:0; }
}




.va_to_down {
  animation-name:to_down;
  animation-fill-mode:both;
  animation-iteration-count:1;
  position:relative;
 }
 
 @keyframes from_down {
  0% { transform:translateY(200px);opacity:0; }
  /* 40% { transform:translateY(-20px); }
  55% { transform:translateY(10px); }
  80% { transform:translateY(-10px); }
  90% { transform:translateY(5px); } */
  100% { transform:translateY(0px);opacity:1; }
}

.va_from_down {
  animation-name:from_down;
  animation-fill-mode:both;
  animation-iteration-count:1;
  position:relative;
  
}


@keyframes scale_out {
  from { transform:scale(1);opacity:1; }
  to { transform:scale(1.1);opacity:0; }
}


.va_scale_out {
  animation-name:scale_out;
  animation-fill-mode:both;
  animation-iteration-count:1;
  transform-origin: 50% 0%;
 }
 
@keyframes scale_in {
  from { transform:scale(0.9);opacity:0; }
  to { transform:scale(1);opacity:1; }
}


.va_scale_in {
  animation-name:scale_in;
  animation-fill-mode:both;
  animation-iteration-count:1;
  transform-origin: 50% 0%;
 }
 
 
@keyframes load_div_box_animation {
  from { left:-2%;width:2%; }
  to { left:100%;width:10%; }
}

.load_div_box {
  animation-name:load_div_box_animation;
  animation-fill-mode:both;
  animation-duration:3s;
  animation-iteration-count:infinite;
  animation-timing-function:ease;  
}

@keyframes background_ball_animation {
	from {opacity:0;}
	to {opacity:0;}
  /*from { filter:blur(0px);transform:rotate(0deg) translate(-400px,-400px) scale(1);opacity:.05 }
  to { filter:blur(10px);transform:rotate(25deg) translate(500px,100px) scale(1.4);opacity:.1 }*/
}

 @keyframes color_change {
  0% { color:$valota_green; }
  100% { color:black; }
}

@keyframes scale_in_hard {
  from { transform:scale(0); }
  to { transform:scale(1); }
}

@keyframes fade_in {
  from { opacity:0; }
  to { opacity:1; }
}


@keyframes anim_fly_in {
  from { 
	  transform:scale(1.4); 
	  opacity:0;
  }
  to { 
	  transform:scale(1); 
	  opacity:1;
  }
}
@keyframes anim_fly_out {
  from { 
	  transform:scale(1);
	  opacity:1;
  }
  to { 
	  transform:scale(.5); 
	  opacity:0;
  }
}



.fly_in {
  animation-name:anim_fly_in;
  animation-fill-mode:both;
  animation-duration:.3s;
  animation-iteration-count:1;
  animation-timing-function:ease-out;  
}
.fly_out {
	animation-name:anim_fly_out;
  animation-fill-mode:both;
  animation-duration:.3s;
  animation-iteration-count:1;
  animation-timing-function:ease-in;
}

 @keyframes color_change_red {
  0% { color:$valota_orange; }
  100% { color:white; }
}

