#data_preview {
        display: none;
        overflow: auto;
        #result{
                background-color: white;
                font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
                box-shadow: 0 1px 2px rgba(0,0,0,0.15);
                padding: 5px;
                height: auto;
                max-height: 500px;
                overflow: auto;
                word-break: normal !important;
                word-wrap: normal !important;
                white-space: pre !important;
        }
        .big_green{
                float: right;
                overflow: hidden;
        }
}


#file_upload {
        display: flex;
        justify-content: center;
        flex-direction: row;
}

#file_upload.custom_data {
        flex-direction: column;
        justify-content: space-around;
        align-content: space-around;
        align-items: center;
}

#loader {
        width:40px;
        height:40px;
        background-image:url('/img/valota_loading_bg.svg.png');
        background-image:url('/img/valota_loading_bg.svg');
        background-size:100% 100%;
        background-position:center;
        background-repeat:no-repeat;
        position:relative;
        vertical-align:middle;
        display:none;
}
#loader.active,#loader_fg.active{
        display:inline-block;
}
#loader.active {
        animation: loading_animation .8s ease infinite;
}

#loader_fg{
        width:40px;
        height:40px;
        background-image:url('/img/valota_loading.svg.png');
        background-image:url('/img/valota_loading.svg');
        background-size:100% 100%;
        background-position:center;
        background-repeat:no-repeat;
        display:none;
        position:relative;
        margin-left:-40px;
        animation:none;
}


#data_preview #result.failure{background-color:rgba(255,0,0,0.2);}
#data_preview #result.success{background-color:rgba(0,255,0,0.2);}